import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import Testimonial from '../components/sections/Testimonial';
import Clients from '../components/sections/Clients';
import Team from '../components/sections/Team';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class Portfolio extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'Newsletter modal is a component commonly used'
    }

    const genericSection02Header = {
      title: 'Button is a component commonly used'
    }

    const genericSection03Header = {
      title: 'Input form is a component commonly used'
    }

    const genericSection04Header = {
      title: 'Frequently asked questions'
    }

    return (
      <React.Fragment>
        <Testimonial className="has-bg-color-cut illustration-section-02" />
        <Clients topDivider bottomDivider />
        {/* <Team /> */}
        {/* <FeaturesSplit invertMobile imageFill topDivider /> */}

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">Tim Matheson - Senior Full Stack Engineer</h2>
            <p>
            An accomplished Full Stack Engineer with extensive experience in developing and delivering scalable solutions using Ruby on Rails and React frameworks. Known for designing intuitive, high-performing applications, they have successfully collaborated with America’s top brands to create tailored, user-focused digital experiences. With a strong focus on clean code, agile development, and cross-functional teamwork, they excel at translating business needs into innovative, high-quality software solutions that drive growth and customer satisfaction.
            </p>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/image-placeholder.jpg')}
                alt="Placeholder"
                width={712}
                height={400} />
              {/* <figcaption className="text-color-low">A super-nice image <span role="img" aria-label="mile">😀</span></figcaption> */}
            </figure>
            <h4>Biography</h4>
            <p>
            A seasoned full stack developer originally from the Midwest, Tim moved to the West Coast in the early 2000s to immerse himself in the burgeoning tech scene. With a passion for innovation and a knack for problem-solving, he worked with numerous startups, building robust web applications and scaling platforms across diverse industries. In 2010, he founded Kamari Labs, a software company dedicated to crafting cutting-edge digital solutions. Known for expertise in modern frameworks, agile development, and entrepreneurial insight, they continue to drive technology forward, empowering businesses to achieve their goals.
            </p>
            <ul>
              <li>Career started in 1998</li>
              <li>Helped Fortune 500 clients go from Start-up to IPO.</li>
              <li>Primary Impact: eCommerce, Apparel, Services, FinTech, Logistics</li>
            </ul>
            <p>
              Tim is dedicated to giving back and supports several local charities.
            </p>
          </div>
        </GenericSection>

        {/* <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection01Header} className="center-content" />
            <div className="center-content">
              <Button
                color="secondary"
                aria-controls="demo-modal"
                onClick={this.openModal}>Open modal</Button>
            </div>
            <Modal
              id="demo-modal"
              show={this.state.demoModalActive}
              handleClose={this.closeModal}
            >
              <div className="center-content">
                <h3 className="mt-16 mb-8">Join our newsletter</h3>
                <p className="text-sm">
                  Magnis dis parturient montes nascetur. Quam quisque id diam vel quam integer.
                </p>
              </div>
              <form style={modalFormStyle}>
                <div className="mb-12">
                  <Input
                    type="email"
                    label="This is a label"
                    placeholder="Your best email"
                    labelHidden />
                </div>
                <Button color="primary" wide>Subscribe</Button>
              </form>
              <div className="center-content mt-24">
                <a
                  className="text-xs"
                  aria-label="close"
                  href="#0"
                  onClick={this.closeModal}
                >No thanks!</a>
              </div>
            </Modal>
          </div >
        </GenericSection> */}

        {/* <GenericSection topDivider className="center-content">
          <div className="container">
            <SectionHeader data={genericSection02Header} className="center-content" />
            <ButtonGroup>
              <Button color="primary" wideMobile>Get started now</Button>
              <Button color="secondary" wideMobile>Get started now</Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button color="dark" wideMobile>Get started now</Button>
              <Button wideMobile>Get started now</Button>
            </ButtonGroup>
          </div>
        </GenericSection> */}

        {/* <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection03Header} className="center-content" />
            <form style={formStyle}>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email.."
                  formGroup="desktop"
                  labelHidden>
                  <Button color="primary">Early access</Button>
                </Input>
              </div>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email.."
                  defaultValue="hello@cruip.com"
                  formGroup="desktop"
                  labelHidden>
                  <Button color="primary" loading>Early access</Button>
                </Input>
              </div>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email.."
                  formGroup="desktop"
                  labelHidden
                  status="error"
                  hint="Something is wrong.">
                  <Button color="primary">Early access</Button>
                </Input>
              </div>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email.."
                  formGroup="desktop"
                  labelHidden
                  status="success"
                  hint="You've done it.">
                  <Button color="primary">Early access</Button>
                </Input>
              </div>
            </form>
          </div>
        </GenericSection> */}

        {/* <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut." active>
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection> */}

        {/* <Cta invertColor split className="has-bg-color-cut" /> */}
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

const modalFormStyle = {
  maxWidth: '320px',
  margin: '0 auto'
}

export default Portfolio;
