import React from 'react';
import classNames from 'classnames';

const Link = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'accordion list-reset mb-0',
    className
  );

  return (
    <a href={props.href} traget="_blank" rel="noopener noreferrer">{props.value || "Default Link" }</a>
  );
}

export default Link;
